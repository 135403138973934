import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser'
import Image from '~components/image'
import {HeroWrapper, HeroInner, HeroContent, HeroImages, HeroImageInner} from './hero.stc'

const Hero = () => {
    const heroQuery = useStaticQuery(graphql `
        query PreviewHeroQuery {
            previewdataJson(id: {eq: "preview-hero-data"}) {
                title
                laptop {
                    childImageSharp {
                        fluid(maxWidth: 1040, maxHeight: 662, quality: 100) {
                            ...GatsbyImageSharpFluid_tracedSVG
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
                tablet {
                    childImageSharp {
                        fluid(maxWidth: 416, maxHeight: 569, quality: 100) {
                            ...GatsbyImageSharpFluid_tracedSVG
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
                phone {
                    childImageSharp {
                        fluid(maxWidth: 229, maxHeight: 427, quality: 100) {
                            ...GatsbyImageSharpFluid_tracedSVG
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    `);
    const {title, laptop, tablet, phone} = heroQuery.previewdataJson;
    return (
        <HeroWrapper>
            <HeroInner>
            </HeroInner>
        </HeroWrapper>
    )
}

export default Hero;
