import React from 'react'
import Anchor from '~components/shared/link'
import logoBlack from '../../../assets/img/logo/BrewSoftwareLogo300.png';
import {HeaderWrap, HeaderInner, HeaderMain, HeaderCol, LogoWrap, PurchaseBtn} from './header.stc'

const Header = () => {
    return (
        <HeaderWrap>
            <HeaderInner>
                <HeaderMain>
                    <HeaderCol left>
                        <LogoWrap>
                            <Anchor path="/">
                                <img src={logoBlack} alt="BrewSoftware Logo"/>
                            </Anchor>
                        </LogoWrap>
                    </HeaderCol>
                    <HeaderCol right>
                    </HeaderCol>
                </HeaderMain>
            </HeaderInner>
        </HeaderWrap>
    )
}

export default Header;
